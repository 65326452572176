import axios from 'axios';
import ReactDOM from 'react-dom/client';
import './index.css'; 
import { setupAxios } from './misc/helpers';
import { LangProvider } from './misc/LangContext';
import { UserProvider } from './misc/UserContext';
import { FC, Suspense, lazy } from 'react';
import { SplashScreen } from './pages/SplashScreen';  
import { FallbackProvider } from './misc/FallbackContext';
import { PageContextProvider } from './misc/PageContext';

// [ ] Suspense fallback kisminda sorun var, daha once yuklendigi halde her defasinda fallback kismina dusuyor. Yoksa iptal etmem gerekir.


const AppRouter = lazy(() => import('./router/AppRouter'))

setupAxios(axios);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  
  <LangProvider>
    <UserProvider>
      
      <Suspense fallback={<SplashScreen />}>
        <PageContextProvider>
          <FallbackProvider>
            <AppRouter />
          </FallbackProvider>
        </PageContextProvider>
      </Suspense>

    </UserProvider>
  </LangProvider>
  // </React.StrictMode>
);
 