import { FC, JSXElementConstructor, ReactElement, ReactNode, createContext, useContext, useState } from "react";
import { PageContextModel, PageFilter, WithChildren, SearchFilter, StatusFilter, PageDetails, AppModal, TaskFields, ModalTypes } from "./models";
import { PopupLoader } from "./PopupLoader";
import { UserWrapper } from "../pages/User/UserWrapper";

const initPageContextModel: PageContextModel = {
    appModals: [],
    setAppModals: () => { },
    pageDetails: undefined,
    setPageDetails: () => { },
    pageFilter: undefined,
    setPageFilter: () => { },
    fields2Show: [],
    setFields2Show: () => { },
    closeLastPopup: () => { },
    addPopup(argElement) { },
}

const PageContext = createContext<PageContextModel>(initPageContextModel);

export const usePage = () => {
    return useContext(PageContext);
}

export const PageContextProvider: FC<WithChildren> = ({ children }) => {

    const [pageFilter, setPageFilter] = useState<PageFilter | undefined>();
    const [pageDetails, setPageDetails] = useState<PageDetails | undefined>();
    const [appModals, setAppModals] = useState<ReactElement[]>([]);
    const [fields2Show, setFields2Show] = useState<TaskFields[]>([]);

    const closeLastPopup = () => {
        setAppModals([...appModals.slice(0, -1)]);
    }

    const addPopup = (argElement: ReactElement) => {
        setAppModals([...appModals, argElement])
    }


    return (
        <PageContext.Provider value={{ pageFilter, setPageFilter, pageDetails, setPageDetails, appModals, setAppModals, fields2Show, setFields2Show, closeLastPopup, addPopup }}>
            {children}
        </PageContext.Provider >);

}
