import axios from "axios";  
import { ProjectModel, RequestModel, TaskModel, TaskTemplateModel, UserModel } from "./models";

 
export const getTemplates =async()=>{
    let result:TaskTemplateModel[]=[];

    try {
        
        console.log("- Loading Templates");
        const res = await axios.get<TaskTemplateModel[]>("/data/taskTemplates.json?");

        console.log("->",res)

        result = res.data;

    } catch (error) {
        console.log("- error:", error)   
    }finally{

        return result;
    }
}
export const getLabels =async()=>{
    let result:string[]=[];

    try {
        
        console.log("- Loading labels");
        const res = await axios.get<string[]>("/data/labels.json?");

        console.log("->",res)

        result = res.data;

    } catch (error) {
        console.log("- error:", error)   
    }finally{

        return result;
    }
}

export const getProjects =async()=>{
    let result:ProjectModel[]=[];

    try {
        
        console.log("- Loading projects");
        const res = await axios.get<ProjectModel[]>("/data/projects.json?");

        console.log("->",res)

        result = res.data;

    } catch (error) {
        console.log("- error:", error)   
    }finally{

        return result;
    }
}

export const getProject =async(argId:string)=>{
    let result:ProjectModel[]=[];

    try {
        
        console.log("- Loading project data", argId);
        const res = await axios.get<ProjectModel[]>("/data/projects.json?projectid="+argId);

        console.log("->",res)

        result = res.data;

    } catch (error) {
        console.log("- error:", error)   
    }finally{

        return result;
    }
}
export const getUsers =async()=>{
    let result:UserModel[]=[];

    try {
        
        console.log("- Loading users ");
        const res = await axios.get<UserModel[]>("/data/users.json");

        console.log("->",res)

        result = res.data;

    } catch (error) {
        console.log("- error:", error)   
    }finally{

        return result;
    }
}
export const userRequest =async(argId:string)=>{
    let result:UserModel[]=[];

    try {
        
        console.log("- Loading user data", argId);
        const res = await axios.get<UserModel[]>("/data/users.json?userid="+argId);

        console.log("->",res.data)

        result = res.data;

    } catch (error) {
        console.log("- error:", error)   
    }finally{

        return result;
    }
}

export const loadDictionary = async(argLang:string) => {
    let result = {};
    try {
        console.log("- loadDictionary started");

        const res = await axios.get<Map<string, string> |undefined>("/data/sozluk.json?lang="+argLang);
 
        res?.data && (result = res?.data);


    } catch (error) {
        console.log("- loadDictionary", error);
    } finally{
        console.log("- loadDictionary Complete");

        return result;
    }
}
export const loadTasks = async(argFilter:string):Promise<RequestModel>=>{
    let result = [];
    try {
        console.log("- Loading Tasks started");
        
        const res = await axios.get("/data/tasks.json?"+argFilter);
        
        res?.data && (result = res?.data);
    } catch (error) {
        console.log("- loadTasks", error);
        
    } finally{
        console.log("- Loading Tasks Complete", result);
        return result;
    }
}