import { useEffect } from "react"; 
import { loadDictionary } from "../misc/requests";
import { UseLang } from "../misc/LangContext";
import { useFallback } from "../misc/FallbackContext";

export const SplashScreen = ()=>{

    const sozluk = UseLang();
    const fallbackContext = useFallback();

    const appDocument = document.documentElement;
    const defaultLang = appDocument.lang;

    useEffect(()=>{
        
        loadDictionary(defaultLang)
        .then((res)=>{
            sozluk.setDictionary(new Map(Object.entries(res)));
            fallbackContext.setIsReady(true);
        });
        
    },[])

    return <>Burasi splash screen</>
}