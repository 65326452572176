import { FC, createContext, useContext, useState } from "react";
import { LangDictionaryModel, WithChildren } from "./models";
 
const langDicInit = { 
    setDictionary:()=>{},
    get:(key: string) => key
}
const LangContext = createContext<LangDictionaryModel>(langDicInit);

export const UseLang = () => {
    return useContext(LangContext);
}

export const LangProvider: FC<WithChildren> = ({children}) =>{

    const [dictionary, setDictionary] = useState<Map<string,string> | undefined>();
    const get =(_key:string)=>dictionary?.get(_key) || _key;
    
   return <LangContext.Provider value={{ setDictionary, get}}>
            {children}
        </LangContext.Provider > ;
 
    
}
 