import { FC, createContext, useContext, useState } from "react";
import { UserContextModel, LoginModel, WithChildren } from "./models";
import { getLoginUser } from "./helpers";


const initUserContextModel: UserContextModel = {
    user: undefined,
    setUser: () => { }
}

const UserContext = createContext<UserContextModel>(initUserContextModel);

export const useUser = () => {
    return useContext(UserContext);
}

export const UserProvider: FC<WithChildren> = ({ children }) => {

    const [user, setUser] = useState<LoginModel | undefined>(getLoginUser());
    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider >);

}
