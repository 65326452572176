import { LS_USER_KEY } from "./variables";
import { LoginModel } from "./models";

export const setupAxios = (axios:any) => {
    axios.defaults.headers.Accept = 'application/json';

    axios.interceptors.request.use(
      (config: {headers: {Authorization: string }}) => {

        const user = getLoginUser(); 

        if (user && user.token) {
          config.headers.Authorization = `Bearer ${user.token}`
        }
  
        return config
        },
        (err: any) => Promise.reject(err)
    )
        
}

export const getLoginUser = (): LoginModel | undefined => {

  return {id:"3", name:"Kursat Kurkaya", email:"kursatkurkaya@gmail.com" } as LoginModel
    // if (!localStorage) {
    //   return
    // }
  
    // const lsValue: string | null = localStorage.getItem(LS_USER_KEY)

    // if (!lsValue) { 
    //   return
    // }
  
    // try {
    //   const user: LoginModel = JSON.parse(lsValue) as LoginModel;

    //   console.error('?? user', user)

    //   if (user) {

    //     return user
    //   }
    // } catch (error) {
    //   console.error('USER LOCAL STORAGE PARSE ERROR', error)
    // }
  }