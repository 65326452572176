import { FC, createContext, useContext, useState } from "react";
import { FallbackContextModel, WithChildren } from "./models";

const initFallbackContextModel:FallbackContextModel = {
    isReady:false,
    setIsReady:()=>{}
}

const FallbackContext = createContext<FallbackContextModel>(initFallbackContextModel);

export const useFallback = () => {
    return useContext(FallbackContext);
}

export const FallbackProvider: FC<WithChildren> = ({children}) =>{

    const [isReady, setIsReady] = useState<boolean>(false);
        
    return ( 
        <FallbackContext.Provider value={{isReady, setIsReady}}>
            {children}
        </FallbackContext.Provider > );
    
}
 